.chat-demo-wrapper a {
  color: #000;
  font-weight: bold; }

.chat-demo-wrapper .button-wrapper {
  justify-content: flex-start;
  margin-left: 1rem; }

.chat-demo-wrapper .MuiInput-underline:before {
  border-bottom: 0; }

.chat-demo-wrapper .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 0; }

.chat-demo-wrapper .rcw-conversation-container .rcw-header {
  padding: 10px;
  background-color: #fff;
  border-bottom: 1px solid;
  color: #1778bd; }
  .chat-demo-wrapper .rcw-conversation-container .rcw-header .rcw-title {
    display: none; }

.chat-demo-wrapper .radioTerms .MuiFormControl-root .MuiFormGroup-root {
  display: flex;
  flex-direction: row; }

.chat-demo-wrapper .chat-image-header {
  max-width: 51%; }

.chat-demo-wrapper .checkbox .label-name {
  display: none; }

.chat-demo-wrapper .form_wrapper {
  padding: 0;
  padding-bottom: 0;
  margin-bottom: 0; }

.chat-demo-wrapper .form_container .field-container {
  padding: 15px;
  background-color: #fff;
  padding-top: 0;
  font-size: 14px;
  margin-bottom: 0; }

.chat-demo-wrapper .rcw-close-launcher {
  max-width: 73px; }

.chat-demo-wrapper .MuiInputBase-input {
  border: 2px solid #ddd;
  border-radius: 4px; }

.chat-demo-wrapper .button-wrapper .MuiButton-outlinedPrimary {
  background-color: #1778bd;
  background-color: #1778bd;
  border-radius: 20px;
  font-weight: bold;
  font-size: 0.8rem;
  margin-bottom: 5px; }

.chat-demo-wrapper .Mui-error .MuiInputBase-input {
  border-color: red; }

.chat-demo-wrapper .MuiInputLabel-formControl {
  font-size: 13px;
  padding-left: 5px; }

.chat-demo-wrapper form#form-conversationForm {
  height: 50vh;
  max-height: 350px;
  padding-left: 20px;
  padding-top: 5px;
  padding-right: 20px; }

.chat-demo-wrapper .form-container {
  background-color: transparent;
  bottom: 0;
  display: none;
  flex-direction: column;
  margin: 0 20px 20px 0;
  max-width: 370px;
  position: fixed;
  right: 0;
  width: 90vw;
  z-index: 10000;
  border-radius: 10px 10px 0 0; }
  .chat-demo-wrapper .form-container p {
    background: #fff;
    margin: 0;
    text-align: center; }
  .chat-demo-wrapper .form-container.zindex2000 {
    z-index: 20000;
    display: flex;
    background-color: #fff;
    box-shadow: 0 2px 10px 1px #b5b5b5; }
  .chat-demo-wrapper .form-container .form-title {
    background-color: #fff;
    border-radius: 10px 10px 0 0;
    color: black;
    display: flex;
    flex-direction: row;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    margin: 0;
    padding: 10px;
    justify-content: space-between; }
  .chat-demo-wrapper .form-container .form-sub-title b {
    color: #1778bd; }
  .chat-demo-wrapper .form-container .form-sub-title {
    padding: 15px;
    font-size: 14px;
    padding-top: 0; }
  .chat-demo-wrapper .form-container .icon-title {
    float: left;
    color: #1778bd;
    font-size: 2rem; }
  .chat-demo-wrapper .form-container .MuiTypography-body1 {
    font-size: 14px; }
  .chat-demo-wrapper .form-container .fake-button {
    border: 0;
    height: 80px;
    width: 100%;
    cursor: pointer;
    z-index: 20000; }
    .chat-demo-wrapper .form-container .fake-button .rcw-fake-close-button {
      display: none; }
  .chat-demo-wrapper .form-container .form-conversation {
    background-color: #fff; }
  .chat-demo-wrapper .form-container .fake-field {
    align-items: center;
    display: flex;
    background-color: #f4f7f9;
    height: 45px;
    padding: 5px;
    border-radius: 0 0 10px 10px; }

@media only screen and (max-width: 800px) {
  .chat-demo-wrapper .form-container {
    width: 100%;
    max-width: 100%;
    margin: 0;
    height: 100%; }
    .chat-demo-wrapper .form-container .fake-button {
      position: relative;
      margin-top: 10px; }
      .chat-demo-wrapper .form-container .fake-button .rcw-fake-close-button {
        display: block;
        position: absolute;
        width: 60px;
        height: 60px;
        left: auto;
        bottom: 15px;
        right: 20px;
        background-color: #35cce6;
        border-radius: 30px;
        border: none; }
        .chat-demo-wrapper .form-container .fake-button .rcw-fake-close-button .rcw-close {
          max-width: 40px;
          max-height: 40px;
          padding: 5px; }
    .chat-demo-wrapper .form-container .fake-field {
      display: none; }
    .chat-demo-wrapper .form-container .form_wrapper {
      height: 100%;
      background: #fff; } }

.chat-demo-wrapper .form_container .material-field {
  min-height: 3rem;
  margin-top: 0.5rem; }

/* .fixedPosition {
  position: fixed;

  top: 374px;
  right: 190px;
} */
/* .positionSpinner {
  position: fixed;
  top: 348px;
  right: 117px;
} */
.big-text {
  font-size: larger; }

.fixed-margin {
  margin-bottom: 2px; }

.scroll {
  overflow-y: scroll; }

.MuiFormGroup-root {
  display: block; }

#formInicio .label-name {
  display: none; }

#formInicio .radioTerms .label-name {
  display: block; }

#formInicio .form-sub-title {
  padding-top: 15px;
  padding-bottom: 2px; }

.button-wrapper .MuiButton-outlinedPrimary:hover {
  background-color: #990000 !important; }

#formInicio .button-wrapper {
  margin-top: 0 !important;
  margin-left: 0 !important;
  justify-content: center; }

.jss8 {
  padding: 1px !important; }

.container-disabled input {
  visibility: hidden;
  pointer-events: none;
  cursor: not-allowed; }

.rcw-message {
  flex-direction: column; }
  .rcw-message img {
    max-width: 200px; }

img.lb-img {
  cursor: pointer !important; }

img.lb-img:not([style="transform: translate3d(0px, 0px, 0px) scale(1) rotate(0deg); cursor: unset; transition: all 0.1s ease 0s;"]) {
  position: fixed;
  left: -100%;
  top: 50px;
  bottom: auto;
  right: auto;
  max-width: 300px;
  transform: translate3d(0, 0, 0) scale(3) !important;
  z-index: 200; }

.rcw-widget-container .rcw-new-message {
  padding-left: 42px;
  padding-right: 38px;
  height: 35px;
  border-radius: 10px; }

.rcw-message .wmp-container,
.rcw-message video {
  max-width: 260px !important;
  max-height: 260px !important; }

.rcw-message .rcw-link {
  font-size: 0; }
  .rcw-message .rcw-link:before {
    content: "Descargar";
    font-size: 12px;
    border: 1px solid #aaa;
    border-radius: 12px;
    background: #ccc;
    padding: 4px 8px; }

.rcw-message .rhap_container {
  max-width: 260px !important; }

.container-disabled input {
  visibility: hidden;
  pointer-events: none;
  cursor: not-allowed; }

.reply-wrapper {
  background-color: #f4f7f9;
  border-radius: 10px;
  max-width: 80%;
  padding: 10px;
  font-family: "Abel", sans-serif !important;
  font-size: 12px !important; }
  .reply-wrapper .caption-title {
    margin-top: 0;
    margin-bottom: 8px;
    font-weight: bold; }
  .reply-wrapper .caption-subtitle {
    margin-bottom: 5px; }
  .reply-wrapper .options {
    margin-top: 5px; }
    .reply-wrapper .options .option-item {
      font-size: 12px;
      border: 1px solid #aaa;
      border-radius: 12px;
      background: #ccc;
      padding: 4px 8px;
      margin-bottom: 10px;
      max-width: 120px;
      text-align: center;
      font-weight: bold;
      cursor: pointer; }

#chatbot-avanti .rcw-sender {
  background: #e8e3e3;
  padding-top: 0px;
  padding-bottom: 0px; }

#chatbot-avanti p {
  font-family: "Abel", sans-serif !important; }

@media only screen and (max-width: 800px) {
  #chatbot-avanti .captcha-container {
    background-color: white; } }

#chatbot-avanti .captchaForm {
  padding: 2rem;
  background-color: white;
  height: 30rem;
  padding-top: 3rem; }
  @media only screen and (max-width: 800px) {
    #chatbot-avanti .captchaForm {
      height: 37rem; } }

#chatbot-avanti .rcw-send:hover {
  background-color: #cdd1cd; }

#chatbot-avanti .rcw-send:active {
  background-color: #cacfca;
  box-shadow: 0 5px #b4b3b3;
  transform: translateY(4px); }

#chatbot-avanti .rcw-send {
  border-radius: 7px; }

#chatbot-avanti .sound-wave {
  max-width: 283px;
  max-height: 34px;
  width: 283px;
  height: 34px;
  padding: 0;
  position: fixed;
  left: auto;
  top: auto;
  bottom: 97px;
  right: 97px;
  z-index: 10001;
  display: none;
  border-radius: 11px; }
  @media only screen and (max-width: 800px) {
    #chatbot-avanti .sound-wave {
      bottom: 5px;
      right: auto;
      left: 11px;
      width: 300px; } }

#chatbot-avanti .attach-widget-microphone:hover {
  background-color: #cdd1cd; }

#chatbot-avanti .attach-widget-send-audio {
  max-width: 30px;
  max-height: 30px;
  width: 30px;
  height: 30px;
  padding: 0;
  position: fixed;
  left: auto;
  top: auto;
  bottom: 97px;
  right: 35px;
  z-index: 10000;
  font-size: 14px;
  min-width: 0;
  display: none; }
  @media only screen and (max-width: 800px) {
    #chatbot-avanti .attach-widget-send-audio {
      bottom: 9px;
      right: auto;
      left: 335px;
      z-index: 10001; } }

#chatbot-avanti .attach-widget-microphone {
  max-width: 30px;
  max-height: 30px;
  width: 30px;
  height: 30px;
  padding: 0;
  position: fixed;
  left: auto;
  top: auto;
  bottom: 97px;
  right: 64px;
  z-index: 10000;
  font-size: 14px;
  min-width: 0;
  display: none; }
  @media only screen and (max-width: 800px) {
    #chatbot-avanti .attach-widget-microphone {
      bottom: 8px;
      right: auto;
      left: 306px;
      z-index: 10001; } }

#chatbot-avanti .attach-widget-file {
  max-width: 30px;
  max-height: 30px;
  width: 30px;
  height: 30px;
  padding: 0;
  /*    position: fixed;
    left: auto;
    top: auto;
    bottom: 96px;
    right: 348px;
*/
  z-index: 10000;
  font-size: 14px;
  cursor: pointer;
  min-width: 0;
  display: none; }
  #chatbot-avanti .attach-widget-file .p-button-label {
    font-size: 0; }
  #chatbot-avanti .attach-widget-file .p-button-icon {
    margin: 0; }
  #chatbot-avanti .attach-widget-file .p-button.p-fileupload-choose {
    padding: 5px; }
  #chatbot-avanti .attach-widget-file .p-button.p-fileupload-choose {
    background-color: #ccc;
    border: none; }
    #chatbot-avanti .attach-widget-file .p-button.p-fileupload-choose:hover {
      background-color: #999; }
  @media only screen and (max-width: 800px) {
    #chatbot-avanti .attach-widget-file {
      bottom: 5px;
      right: auto;
      left: 10px; } }

.chat-opened #chatbot-avanti .container-disabled .attach-widget-file {
  display: none; }

.chat-opened #chatbot-avanti .container-disabled .attach-widget-microphone {
  display: none; }

.chat-opened #chatbot-avanti .attach-widget-file {
  display: block; }

.chat-opened #chatbot-avanti .attach-widget-microphone {
  display: block; }

.loading-layout {
  width: 90vw;
  height: 57vh;
  background-color: #000;
  opacity: 0.5;
  z-index: 10002;
  position: fixed;
  right: 0;
  flex-direction: column;
  max-width: 370px;
  bottom: 70px;
  margin: 0 20px 20px 0; }
  @media only screen and (max-width: 800px) {
    .loading-layout {
      margin: 0;
      width: 100vw;
      max-width: 100vw;
      height: 70vh;
      bottom: 45px; } }
  .loading-layout .circular-message-loading {
    left: 20vw;
    top: 20vh;
    position: absolute; }
    @media only screen and (max-width: 800px) {
      .loading-layout .circular-message-loading {
        left: 45vw;
        top: 30vh; } }
  .loading-layout .spinner-text {
    left: 19vw;
    top: 29vh;
    position: absolute;
    font-size: 15px;
    color: #fff; }
    @media only screen and (max-width: 800px) {
      .loading-layout .spinner-text {
        left: 45vw;
        top: 30vh;
        left: 43vw;
        top: 37vh; } }

.rcw-client.client-message {
  background-color: #a3eaf7;
  border-radius: 10px;
  padding: 15px;
  max-width: 215px;
  text-align: left; }

.client-message .wmp-container,
.client-message video,
.client-message .wmp-title-container {
  max-width: 180px !important;
  max-height: 200px !important; }

.panelReply .p-panel-content {
  padding: 0.5rem !important; }

.panelReply .p-panel-title {
  font-family: 'Abel';
  font-weight: bold;
  font-size: 1.2rem; }

.panelReply .p-panel-header {
  justify-content: normal !important;
  padding-right: 0rem; }

.list-container {
  border-radius: 8px 8px 0 0;
  font-family: "Abel", sans-serif !important;
  font-size: 13px !important;
  line-height: 1em !important; }
  .list-container .caption {
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 8px 8px 0 0;
    position: relative;
    background: #333; }
    .list-container .caption.absolute-layout {
      min-height: 95px; }
    .list-container .caption .header-image,
    .list-container .caption .gray-layout {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      max-width: 100%;
      border-radius: 8px 8px 0 0; }
    .list-container .caption .gray-layout {
      background: #000;
      opacity: 0.6;
      display: none; }
      .list-container .caption .gray-layout.absolute-layout {
        display: block; }
    .list-container .caption .caption-title {
      font-weight: bold;
      color: #eee;
      text-shadow: 1px 1px #000; }
    .list-container .caption .close-caption {
      top: auto;
      bottom: 5px; }
      .list-container .caption .close-caption.absolute-layout {
        position: absolute; }
    .list-container .caption .caption-subtitle {
      color: #eee;
      text-shadow: 1px 1px #000; }
  .list-container .caption-subtitle {
    width: 100%;
    text-align: left; }
  .list-container .list-item {
    padding: 5px;
    display: flex; }
    .list-container .list-item .right-item {
      display: flex;
      justify-content: center;
      align-items: center; }
      .list-container .list-item .right-item img {
        border-radius: 4px; }
    .list-container .list-item .option-item {
      margin-top: 0.4rem;
      max-width: 80%;
      border: 1px solid #10346e;
      border-radius: 4px;
      padding: 2px;
      color: #10346e;
      cursor: pointer;
      text-align: center; }
      .list-container .list-item .option-item:hover {
        background-color: #10346e;
        color: #fff;
        font-weight: bold; }
    .list-container .list-item .left-item {
      width: 100%; }
    .list-container .list-item .right-item {
      width: 30%;
      border-radius: 4px; }
      .list-container .list-item .right-item .item-image {
        width: 100%; }
    .list-container .list-item .item-title {
      font-weight: bold;
      color: #000; }
    .list-container .list-item .item-subtitle {
      color: #444; }

.rcw-response .rcw-message-text {
  max-width: 80%; }

.encuestaStyles .MuiFormGroup-root {
  flex-direction: initial !important; }

.encuestaStyles .MuiRadio-root {
  padding: 2px !important; }

.encuestaStyles .form-title {
  display: initial !important;
  font-weight: 600 !important; }

.encuestaStyles .subtitleStyle {
  font-size: 15px !important; }

/* #chatbot-avanti {
  .rcw-widget-container {
    height: auto;
    width: auto;

  }

} */
/*  .chat-opened {
  #chatbot-avanti {
    .rcw-widget-container {
      height: 82vh;
     
    }

  }

}  */
@media only screen and (max-width: 800px) {
  .chat-opened #chatbot-avanti .rcw-widget-container {
    height: 85vh; }
  .rcw-widget-container .rcw-launcher.rcw-hide-sm {
    display: none; }
  .chat-demo-wrapper .form-container {
    height: 85vh; }
  .rcw-conversation-container .rcw-header .rcw-close-button {
    width: 35px;
    height: 35px;
    border-radius: 23px;
    padding-top: 3px;
    top: 10px; } }

@media only screen and (max-width: 800px) and (max-width: 800px) {
  .chat-opened .bodyChat .rcw-widget-container,
  .chat-opened .chat-demo-wrapper .rcw-widget-container {
    height: 85vh !important; } }

@media only screen and (max-width: 800px) {
  .main-wrapper {
    z-index: 99 !important; }
  /*  .main-wrapper, :focus, :hover{
        z-index: 102;
    } */ }

.main-wrapper {
  z-index: 3000;
  position: fixed; }

.richtext-message {
  background-color: #f4f7f9;
  border-radius: 10px;
  padding: 15px;
  max-width: 90%;
  text-align: left; }

.rcw-message p {
  margin-top: 0; }

@media only screen and (max-width: 380px) {
  #chatbot-avanti.chaticoGAB .rcw-launcher {
    right: 35px; } }

#chatbot-avanti.chaticoGobiernoGov .rcw-widget-container {
  bottom: 10vh;
  margin: 0 25px 20px 0; }

#chatbot-avanti {
  /* ALCALDIA DE BOGOTA 1029*/
  /*superfinaniera */ }
  #chatbot-avanti .chatbot-avanti-1029 {
    /* .rcw-messages-container::-webkit-scrollbar {
    display: none;
} */
    /* Hide scrollbar for IE, Edge and Firefox */
    /* .rcw-messages-container {
    -ms-overflow-style: none;
 
    scrollbar-width: none;
   
} */ }
    #chatbot-avanti .chatbot-avanti-1029 .captchaForm {
      height: 24rem; }
    #chatbot-avanti .chatbot-avanti-1029 .positionSpinner {
      display: flex;
      justify-content: center;
      position: relative;
      bottom: 22rem; }
    #chatbot-avanti .chatbot-avanti-1029 .titleSubtitle {
      width: 100%;
      margin-top: 1.6px; }
      #chatbot-avanti .chatbot-avanti-1029 .titleSubtitle .subtitle {
        font-size: 17.6px;
        font-family: 'abel';
        width: 97%; }
    #chatbot-avanti .chatbot-avanti-1029 .pi-plus:before {
      content: "\002B" !important; }
    #chatbot-avanti .chatbot-avanti-1029 .pi-chevron-down:before {
      content: "\25BC" !important; }
    #chatbot-avanti .chatbot-avanti-1029 .pi-chevron-up:before {
      content: "\25B2" !important; }
    #chatbot-avanti .chatbot-avanti-1029 .fixedPosition {
      position: relative;
      bottom: 5rem;
      left: 7rem;
      justify-content: center;
      display: flex !important; }
    #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .attach-widget-file {
      color: rgba(215, 34, 34, 0.768627);
      font-size: 1.2rem;
      font-weight: bold; }
      #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .attach-widget-file svg {
        font-size: 2rem; }
      @media only screen and (max-width: 800px) {
        #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .attach-widget-file {
          bottom: 18px;
          left: 15px; } }
    #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .attach-widget-microphone {
      height: 23px;
      right: 73px;
      display: none; }
      #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .attach-widget-microphone .MuiSvgIcon-root {
        fill: rgba(215, 34, 34, 0.768627); }
      @media only screen and (max-width: 800px) {
        #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .attach-widget-microphone {
          bottom: 19px;
          left: 324px; } }
    #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .sound-wave {
      width: 275px;
      height: 29px;
      bottom: 91px; }
      @media only screen and (max-width: 800px) {
        #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .sound-wave {
          bottom: 13px;
          left: 43px; } }
    #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .attach-widget-send-audio .MuiSvgIcon-root {
      fill: rgba(215, 34, 34, 0.768627);
      font-size: 25px;
      height: 3.5rem; }
    @media only screen and (max-width: 800px) {
      #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .attach-widget-send-audio {
        bottom: 19px;
        left: 360px; } }
    #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .rcw-client.client-message {
      background-color: #fafafa;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.54) 0px 1px 3px -1px; }
    #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .rcw-sender {
      height: 55px;
      background: white; }
    #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .rcw-widget-container .rcw-new-message {
      height: 45px;
      border-width: 2px;
      border-style: groove;
      border-color: #ff00008f;
      padding-left: 45px;
      padding-right: 34px;
      border-radius: 22px;
      font-family: sans-serif;
      font-size: 12px; }
    #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .rcw-avatar {
      min-width: 15px;
      margin-right: 4px;
      width: 30px;
      height: 30px; }
    #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .form-container {
      background-color: #fff; }
      #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .form-container .fake-field {
        background-color: #fff; }
    #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .form-container .fake-button .rcw-fake-close-button {
      background-color: #d82200; }
    #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .form-container .form-title {
      background-color: #d82200;
      display: flex;
      justify-content: center;
      min-height: 150px;
      align-items: center;
      border-radius: 1vmin 1vmin 3vmin 3vmin; }
      #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .form-container .form-title .icon-title {
        display: none; }
      #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .form-container .form-title .image-title {
        max-height: 80px; }
    #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .form-container .fake-button .rcw-fake-close-button {
      display: block;
      position: absolute;
      width: 60px;
      height: 60px;
      left: auto;
      bottom: 15px;
      right: 20px;
      border-radius: 30px;
      border: none; }
    #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .form-container .rcw-fake-close-button .rcw-close {
      max-width: 40px;
      max-height: 40px;
      padding: 5px; }
    #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .rcw-launcher {
      background-color: rgba(0, 0, 0, 0);
      width: 70px;
      height: 70px;
      /* height: 70px;
        width: 70px; */
      padding: 0px 0px 0px 0px;
      box-shadow: none; }
      #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .rcw-launcher img {
        width: 73px; }
      @media only screen and (max-width: 800px) {
        #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .rcw-launcher {
          width: 60px !important;
          height: 60px !important;
          background-color: rgba(0, 0, 0, 0); } }
    #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .captchaForm button {
      background-color: #d82200;
      min-width: 180px;
      border-color: #d82200; }
    #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .rcw-picker-btn,
    #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .rcw-send {
      /* background: transparent; */
      border-radius: 15px;
      border: 0;
      cursor: pointer;
      height: 45px;
      border-width: 2px;
      border-style: groove;
      border-color: #ff00008f; }
    #chatbot-avanti .chatbot-avanti-1029 .chat-demo-wrapper .chat-image-header {
      max-height: 80px;
      margin: initial; }
    #chatbot-avanti .chatbot-avanti-1029 .rcw-messages-container {
      height: 56vh; }
      @media only screen and (max-width: 800px) {
        #chatbot-avanti .chatbot-avanti-1029 .rcw-messages-container {
          height: 100%; } }
    #chatbot-avanti .chatbot-avanti-1029 .rcw-badge {
      display: none; }
    #chatbot-avanti .chatbot-avanti-1029 .encuestaStyles {
      border-radius: 11px 10px 10px 10px !important; }
      #chatbot-avanti .chatbot-avanti-1029 .encuestaStyles .form-title {
        border-radius: 1vmin 1vmin 0vmin 0vmin !important;
        min-height: 65px !important;
        color: #fafafa;
        padding-top: 15px; }
      #chatbot-avanti .chatbot-avanti-1029 .encuestaStyles .subtitleStyle {
        margin-top: 10px !important;
        font-size: 17px !important;
        font-weight: 600 !important; }
      #chatbot-avanti .chatbot-avanti-1029 .encuestaStyles .whiteBackground {
        justify-content: center;
        margin-left: 0rem;
        margin-bottom: 10px; }
        #chatbot-avanti .chatbot-avanti-1029 .encuestaStyles .whiteBackground .MuiButton-outlinedPrimary {
          background-color: #d82200;
          border-radius: 5px;
          margin-left: 12px; }
      #chatbot-avanti .chatbot-avanti-1029 .encuestaStyles .radioButtonLabel {
        font-size: 16px !important; }
      #chatbot-avanti .chatbot-avanti-1029 .encuestaStyles .ratingStarsLabel {
        font-size: 16px !important; }
      #chatbot-avanti .chatbot-avanti-1029 .encuestaStyles .MuiFormGroup-root {
        justify-content: center !important; }
    #chatbot-avanti .chatbot-avanti-1029 .rcw-widget-container .rcw-header {
      background-color: #d82200;
      display: flex;
      justify-content: center;
      /* border-radius: 1vmin 1vmin 3vmin 3vmin; */
      padding-left: 10px;
      padding-top: 2px;
      padding-bottom: 0px;
      border-bottom: 1px solid; }
    #chatbot-avanti .chatbot-avanti-1029 .rcw-message {
      flex-direction: row;
      margin-left: 4px;
      /* 
    #chatbot-avanti .chat-closed .rcw-launcher,
    #chatbot-avanti .rcw-launcher:not(.rcw-hide-sm) {
        background-image: url('https://firebasestorage.googleapis.com/v0/b/chat-asesores-prueba.appspot.com/o/alcaldiaBogota%2Flogo_chatico%20(2).png?alt=media&token=d3e50bea-2ab0-4ea1-a253-c4ad880169eb');
        background-repeat: no-repeat;
        width: 100px;
        border-radius: 0;
        background-color: #3366cc;
        max-height: 40px;
    } */ }
      #chatbot-avanti .chatbot-avanti-1029 .rcw-message .reply-wrapper {
        border-radius: 0px 10px 10px 10px;
        background-color: #fafafa;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        width: 80%;
        max-width: 100%; }
        #chatbot-avanti .chatbot-avanti-1029 .rcw-message .reply-wrapper .options .option-item {
          background-color: transparent;
          border-color: #d82200;
          font-size: 18px;
          max-width: 100%;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; }
          #chatbot-avanti .chatbot-avanti-1029 .rcw-message .reply-wrapper .options .option-item:hover {
            background-color: #d82200;
            color: #fff; }
      #chatbot-avanti .chatbot-avanti-1029 .rcw-message .richtext-message {
        border-radius: 0px 10px 10px 10px;
        background-color: #fafafa;
        max-width: 80%;
        padding: 10px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.54) 0px 1px 3px -1px; }
        #chatbot-avanti .chatbot-avanti-1029 .rcw-message .richtext-message ol {
          font-size: 17.6px;
          margin-top: 15px; }
          #chatbot-avanti .chatbot-avanti-1029 .rcw-message .richtext-message ol li {
            font-size: 16px; }
        #chatbot-avanti .chatbot-avanti-1029 .rcw-message .richtext-message ul {
          font-size: 16px; }
        #chatbot-avanti .chatbot-avanti-1029 .rcw-message .richtext-message p a:hover {
          color: #349bf0; }
      #chatbot-avanti .chatbot-avanti-1029 .rcw-message p,
      #chatbot-avanti .chatbot-avanti-1029 .rcw-message span {
        font-size: 17.6px;
        margin-bottom: 2px;
        width: 100%;
        line-height: 20px; }
      #chatbot-avanti .chatbot-avanti-1029 .rcw-message .rcw-message-text {
        border-radius: 0px 10px 10px 10px;
        background-color: #fafafa;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        padding: 10px; }
      #chatbot-avanti .chatbot-avanti-1029 .rcw-message .panelReply {
        border-radius: 0px 10px 10px 10px;
        max-width: 86%;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; }
        #chatbot-avanti .chatbot-avanti-1029 .rcw-message .panelReply .p-panel-content {
          background-color: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
          border-radius: 0px 0px 10px 10px; }
        #chatbot-avanti .chatbot-avanti-1029 .rcw-message .panelReply .p-panel-header {
          background-color: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; }
      #chatbot-avanti .chatbot-avanti-1029 .rcw-message .rcw-client .rcw-message-text {
        background-color: #d82200;
        color: #fff;
        font-weight: bold;
        margin-right: 7px;
        border-radius: 10px 10px 0px 10px; }
      #chatbot-avanti .chatbot-avanti-1029 .rcw-message .rcw-client p {
        color: white; }
      #chatbot-avanti .chatbot-avanti-1029 .rcw-message .list-items-wrapper .grid {
        display: block; }
        #chatbot-avanti .chatbot-avanti-1029 .rcw-message .list-items-wrapper .grid .list-item {
          padding: 2px;
          margin-right: auto;
          margin-left: auto;
          width: 90%;
          max-width: 100%; }
      #chatbot-avanti .chatbot-avanti-1029 .rcw-message .list-container.list-message .list-item .option-item {
        background-color: #fff;
        color: black;
        font-weight: bold;
        border: 1px solid #d82200;
        border-radius: 12px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 100%;
        font-size: 18px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; }
        #chatbot-avanti .chatbot-avanti-1029 .rcw-message .list-container.list-message .list-item .option-item:hover {
          background-color: #d82200;
          color: #fff;
          font-weight: bold; }
      #chatbot-avanti .chatbot-avanti-1029 .rcw-message img {
        margin: initial; }
    #chatbot-avanti .chatbot-avanti-1029 .rcw-widget-container .rcw-header {
      min-height: 50px; }
    @media only screen and (max-width: 800px) {
      #chatbot-avanti .chatbot-avanti-1029 .chat-closed {
        height: 0vh; } }
    #chatbot-avanti .chatbot-avanti-1029 .rcw-conversation-container .rcw-close-button {
      background-color: #d82200; }
    #chatbot-avanti .chatbot-avanti-1029 #spinnerLoading {
      position: absolute;
      z-index: 20000;
      margin-left: 80px; }
      @media only screen and (max-width: 800px) {
        #chatbot-avanti .chatbot-avanti-1029 #spinnerLoading {
          margin-left: 115px; } }
    @media only screen and (max-width: 800px) {
      #chatbot-avanti .chatbot-avanti-1029 .dfSpinnerContainer {
        bottom: 55px;
        right: 335px; } }
    #chatbot-avanti .chatbot-avanti-1029 .dfSpinnerContainer .MuiLinearProgress-barColorSecondary {
      background-color: #d82200; }
    #chatbot-avanti .chatbot-avanti-1029 .list-item .item-title {
      display: none; }
  #chatbot-avanti .chatbot-avanti-1023 .fixedPosition {
    position: fixed;
    top: 374px;
    right: 190px; }
  #chatbot-avanti .chatbot-avanti-1023 .encuestaStyles {
    border-radius: 11px 10px 10px 10px !important; }
    #chatbot-avanti .chatbot-avanti-1023 .encuestaStyles .form-title {
      border-radius: 1vmin 1vmin 0vmin 0vmin !important;
      min-height: 35px !important;
      color: #1013c2;
      padding-top: 15px; }
    #chatbot-avanti .chatbot-avanti-1023 .encuestaStyles .subtitleStyle {
      margin-top: 10px !important;
      font-size: 17px !important;
      font-weight: 600 !important; }
    #chatbot-avanti .chatbot-avanti-1023 .encuestaStyles .whiteBackground {
      justify-content: center;
      margin-left: 0rem;
      margin-bottom: 10px; }
      #chatbot-avanti .chatbot-avanti-1023 .encuestaStyles .whiteBackground .MuiButton-outlinedPrimary {
        background-color: #1013c2;
        border-radius: 5px;
        margin-left: 12px; }
    #chatbot-avanti .chatbot-avanti-1023 .encuestaStyles .radioButtonLabel {
      font-size: 16px !important; }
    #chatbot-avanti .chatbot-avanti-1023 .encuestaStyles .ratingStarsLabel {
      font-size: 16px !important; }
    #chatbot-avanti .chatbot-avanti-1023 .encuestaStyles .MuiFormGroup-root {
      justify-content: center !important; }
  #chatbot-avanti .chatbot-avanti-1023 .form_container.conversation {
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 29rem;
    height: 29rem;
    background-color: white; }
  #chatbot-avanti .chatbot-avanti-1023 .titleSubtitle {
    width: 79%;
    margin-top: 0.1rem; }
  #chatbot-avanti .chatbot-avanti-1023 .positionSpinner {
    position: fixed;
    top: 348px;
    right: 117px; }
  #chatbot-avanti .chatbot-avanti-1023 .captchaForm {
    height: 24rem; }
  @media only screen and (max-width: 800px) {
    #chatbot-avanti .chatbot-avanti-1023 .dfSpinnerContainer {
      bottom: 50px;
      right: 335px; } }
  #chatbot-avanti .chatbot-avanti-1023 .dfSpinnerContainer .MuiLinearProgress-colorSecondary {
    background-color: #a7bfeb; }
  #chatbot-avanti .chatbot-avanti-1023 .dfSpinnerContainer .MuiLinearProgress-barColorSecondary {
    background-color: #3474ec; }
  #chatbot-avanti .chatbot-avanti-1023 .iconsContainer {
    bottom: 76px !important; }
  #chatbot-avanti .chat-demo-wrapper .form-container.formInicio {
    background-color: transparent; }
    #chatbot-avanti .chat-demo-wrapper .form-container.formInicio .fake-field {
      background-color: transparent; }
    #chatbot-avanti .chat-demo-wrapper .form-container.formInicio.formCaptcha {
      box-shadow: 0 2px 10px 1px #b5b5b5;
      background-color: #fff; }
      #chatbot-avanti .chat-demo-wrapper .form-container.formInicio.formCaptcha .fake-field {
        background-color: #fff; }

/* 
.chat-opened #chatbot-avanti{

  .chatbot-avanti-1023 {
    .rcw-widget-container {
      height: 77vh;
  }

  }

}
 */
.richtext-message a {
  color: #1778bd !important; }

.chat-widget-panel {
  position: relative; }

.dfSpinnerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 140px;
  right: 314px;
  z-index: 20000;
  position: fixed; }
  .dfSpinnerContainer .df-cargando {
    font-weight: bold;
    text-shadow: 2px 2px #eee; }
  .dfSpinnerContainer .dialogFlowSpinner {
    width: 60px;
    height: 4px; }

.rcwChatStyle {
  bottom: 0;
  display: flex;
  flex-direction: column;
  margin: 0 20px 20px 0;
  max-width: 370px;
  position: fixed;
  right: 0;
  width: 90vw;
  height: 77vh;
  /*   z-index: 10000; */ }

.chat-closed {
  width: 9vw !important; }

.iconsContainer {
  position: absolute;
  bottom: 68px !important;
  right: 326px; }
