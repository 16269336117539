.form_wrapper {
  padding: 2rem; }
  .form_wrapper .form-title {
    padding: 5px; }
    .form_wrapper .form-title .image-title {
      max-width: 140px; }
      @media only screen and (max-width: 800px) {
        .form_wrapper .form-title .image-title {
          max-height: 50px; } }
  .form_wrapper .p-rating {
    width: 100%;
    float: left;
    text-align: LEFT;
    margin-top: 2rem; }
  .form_wrapper .form_container.conversation {
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 400px; }
  .form_wrapper .helptext {
    float: left;
    margin-right: 1rem; }
  .form_wrapper .form-sub-title {
    text-align: left; }
  .form_wrapper .table .form-child li {
    position: relative; }
  .form_wrapper .table .form-child h4 {
    display: none; }
  .form_wrapper .table .form-child .button-wrapper.delete {
    position: absolute;
    top: 0;
    margin: 0; }
  .form_wrapper .table .form-child .field-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    margin: 0; }
    .form_wrapper .table .form-child .field-container .material-field {
      width: 100%;
      font-size: 1rem;
      min-height: 2rem;
      border: 1px solid #333; }
      .form_wrapper .table .form-child .field-container .material-field .label-name {
        display: none; }
      .form_wrapper .table .form-child .field-container .material-field label, .form_wrapper .table .form-child .field-container .material-field .p-fileupload-choose {
        font-size: 0.8rem;
        line-height: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 120px;
        font-weight: bold; }
      .form_wrapper .table .form-child .field-container .material-field .p-fileupload-choose {
        background: transparent;
        border: none;
        color: rgba(0, 0, 0, 0.54);
        font-weight: bold;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        padding-top: 24px; }

.form_container.spacer {
  min-height: 67px; }
  .form_container.spacer.last {
    min-height: 68px; }

.form_container .field-container {
  display: inline-block;
  height: auto;
  width: 100%;
  margin-bottom: 1rem; }

.form_container .label-name {
  float: left; }

.form_container .material-field {
  min-height: 6rem;
  margin-top: 1rem; }
  .form_container .material-field .MuiFormControl-root {
    width: 100%; }
    .form_container .material-field .MuiFormControl-root.datepicker {
      margin-top: 1rem; }
  .form_container .material-field.rs-100 {
    width: 100%; }
  .form_container .material-field.rs-75 {
    width: 73%;
    margin-right: 2%;
    float: left; }
  .form_container .material-field.rs-60 {
    width: 58%;
    margin-right: 2%;
    float: left; }
  .form_container .material-field.rs-50 {
    width: 48%;
    margin-right: 2%;
    float: left; }
  .form_container .material-field.rs-30 {
    width: 28%;
    margin-right: 2%;
    float: left; }
  .form_container .material-field.rs-40 {
    width: 38%;
    margin-right: 2%;
    float: left; }
  .form_container .material-field.rs-25 {
    width: 23%;
    margin-right: 2%;
    float: left; }
  .form_container .material-field.rs-20 {
    width: 18%;
    margin-right: 2%;
    float: left; }
  .form_container .material-field.rs-15 {
    width: 13%;
    margin-right: 2%;
    float: left; }
  .form_container .material-field.rs-100 .custom-input, .form_container .material-field.rs-75 .custom-input, .form_container .material-field.rs-60 .custom-input, .form_container .material-field.rs-50 .custom-input, .form_container .material-field.rs-40 .custom-input, .form_container .material-field.rs-30 .custom-input, .form_container .material-field.rs-25 .custom-input {
    flex: 0 100%; }
  @media only screen and (max-width: 800px) {
    .form_container .material-field.rs-100, .form_container .material-field.rs-75, .form_container .material-field.rs-60, .form_container .material-field.rs-50, .form_container .material-field.rs-40, .form_container .material-field.rs-30, .form_container .material-field.rs-25 {
      width: 100%;
      margin-right: 0;
      float: left; } }

.button-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 1rem; }
  .button-wrapper .MuiButton-outlinedPrimary {
    color: white;
    background-color: #085E14;
    border: none; }
    .button-wrapper .MuiButton-outlinedPrimary:hover {
      background-color: #95c21d;
      border: none; }
    .button-wrapper .MuiButton-outlinedPrimary[disabled=disabled], .button-wrapper .MuiButton-outlinedPrimary:disabled {
      background-color: #ececec;
      color: #23232e; }
  .button-wrapper .MuiButton-outlinedSecondary {
    color: white;
    background-color: #23232e;
    border: none; }
    .button-wrapper .MuiButton-outlinedSecondary:hover {
      background-color: #555558;
      border: none; }
    .button-wrapper .MuiButton-outlinedSecondary[disabled=disabled], .button-wrapper .MuiButton-outlinedSecondary:disabled {
      background-color: #ececec;
      color: #23232e; }
  .button-wrapper.delete {
    justify-content: flex-end; }

.section-container {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 1rem;
  margin-bottom: 1rem; }

.form-child {
  display: flex;
  width: 100%;
  flex-direction: column;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 1rem; }
  .form-child h4 {
    text-align: left; }

.p-fileupload {
  display: flex; }

.p-button.p-fileupload-choose {
  background-color: #085E14;
  border: 1px solid #085E14; }

.form-tooltip {
  float: left;
  margin-right: 0.5rem; }

.no-visible {
  visibility: hidden !important; }
